@use 'styles/utilities/_variables' as common;
@use 'styles/DesignSystem/_colors' as colors;

.homepage-page-wrapper {
  font-family: 'Lato', sans-serif;	
}

.testimonial-publication-container {
  background-image: url('/static/images/homepage/ellipse-bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 104px;
  padding-top: 72px;

  @media(min-width: common.$bootstrap-md-min-width) {
    background-position-y: 54px, center;
    flex-direction: column;
    margin-top: 0;
    padding: 0;
  }

  @media(min-width: common.$bootstrap-lg-min-width) {
    background-position-y: 60px, center;
    margin-top: 54px;
  }
}

.info-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;

  @media(min-width: common.$bootstrap-md-min-width) {
    margin: 0 66px;
    padding-top: 42px;
  }
}

.border {
  border-top: 1px solid colors.$dumbo;
  height: 1px;
  width: 120px;
}

.browse-container {
  display: inline-block;
  padding: 42px 0;
  text-align: center;

  @media(min-width: common.$bootstrap-md-min-width) {
    display: flex;
    justify-content: center;
  }

  a {
    color: colors.$dory;
    text-decoration: underline;
  }
}
