@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/_variables' as common;

.testimonial-container {
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(15, 26, 43, 0.25);
  display: flex;
  flex-direction: column;
  height: 195px;
  margin-right: 0;
  padding: 24px 18px;
  position: relative;
  text-align: center;
  width: 230px;

  &:not(:last-child) {
    margin-bottom: inherit;
  }

  p {
    margin: 0;
  }

  @media(min-width: common.$bootstrap-md-min-width) {
    height: 201px;
    width: 242px;
  }
}

.review {
  font-weight: 700;
  padding-bottom: 12px;
}

.rating-container {
  align-items: center;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  position: absolute;
}

.source {
  color: colors.$edna-light;
  padding-left: 4px;

  a {
    color: inherit;
    text-decoration: none;
  }
}
