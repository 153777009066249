@use 'styles/utilities/_variables' as common;

.testimonials-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  max-height: 651px;
  padding: 0 15px;
  width: 100%;

  @media(min-width: common.$bootstrap-md-min-width) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 810px;
  }
}

