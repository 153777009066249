@use 'styles/utilities/_variables' as common;

.sources-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
  width: 100%;

  @media(min-width: common.$bootstrap-md-min-width) {
    padding-top: 48px;
  }
  
  @media(min-width: common.$bootstrap-lg-min-width) {
    flex-direction: row;
    padding: 64px 0;
  }
}

.first-half-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0;
  width: 100%;
  
  img {
    margin-bottom: 24px;
  }

  @media(min-width: common.$bootstrap-md-min-width) {
    flex-direction: row;
    padding-bottom: 30px;
    width: 608px;
    
    img {
      margin: 0;
    }
  }

  @media(min-width: common.$bootstrap-lg-min-width) {
    justify-content: center;
    padding-bottom: 0;

    img {
      margin-right: 30px;
    }
  }
}

.last-half-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 292px;

  @media(min-width: common.$bootstrap-lg-min-width) {
    justify-content: center;

    img:not(:last-child) {
      margin-right: 30px;
    }
  }
}
