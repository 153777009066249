$dumbo-lighter: #f9fbff;
$dumbo-light: #edf2f9;
$dumbo: #dae1ed;
$dumbo-dark: #c5cddb;
$dumbo-darker: #b1bbcc;

$edna-lighter: #8c99ad;
$edna-light: #66748a;
$edna: #37455b;
$edna-dark: #212d42;
$edna-darker: #0f1a2b;

$elsa-lighter: #f1fbff;
$elsa-light: #dff7ff;
$elsa: #bceeff;
$elsa-dark: #8ce2ff;
$elsa-darker: #53c4ea;

$dory-lighter: #00a4db;
$dory-light: #08c;
$dory: #0070a9;
$dory-dark: #005581;
$dory-darker: #004060;

$peter-pan-lighter: #cfc;
$peter-pan-light: #95ed95;
$peter-pan: #5ebb5e;
$peter-pan-dark: #359935;
$peter-pan-darker: #277327;

$simba-lighter: #fff6db;
$simba-light: #ffeeb8;
$simba: #ffde73;
$simba-dark: #f1b021;
$simba-darker: #b8790b;
$simba-darkest: #895701;

$sebastian-lighter: #ffe0e0;
$sebastian-light: #ffa3a3;
$sebastian: #ee6a6a;
$sebastian-dark: #d65555;
$sebastian-darker: #c15555;

$ursula-light: #d7c5ed;
$ursula-darker: #5f3394;

// Basic colors
$white: #fff;
$black: #000;

// Secondary Button Colors
$secondary-btn-color: #ebeff5;
$secondary-btn-color-dark: #dadfe8;
$secondary-btn-color-darker: #ced3db;
$secondary-dark-btn-color: #d0d7e5;
$secondary-dark-btn-color-dark: #a7b0bf;
$secondary-dark-btn-color-darker: #7d8799;

// Split Dropdown Button Colors
$blue-hover-background-color: #08c;
$white-hover-text-color: #fff;

// Transparent Colors
$dory-light-transparent: rgba(115, 208, 255, 0.05);
$edna-transparent: rgba($edna, 0.1);
$edna-active-transparent: rgba($edna, 0.15);
$edna-more-active-transparent: rgba($edna, 0.2);

// Alert colors
$success-alert-background-color: #cfc;

// Text color
$dark-text-blue-color: #589ab9;
$light-text-color: #fff;

$color-map: (
  'dumbo-lighter': $dumbo-lighter,
  'dumbo-light': $dumbo-light,
  'dumbo': $dumbo,
  'dumbo-dark': $dumbo-dark,
  'dumbo-darker': $dumbo-darker,

  'edna-lighter': $edna-lighter,
  'edna-light': $edna-light,
  'edna': $edna,
  'edna-dark': $edna-dark,
  'edna-darker': $edna-darker,

  'elsa-lighter': $elsa-lighter,
  'elsa-light': $elsa-light,
  'elsa': $elsa,
  'elsa-dark': $elsa-dark,
  'elsa-darker': $elsa-darker,

  'dory-lighter': $dory-lighter,
  'dory-light': $dory-light,
  'dory': $dory,
  'dory-dark': $dory-dark,
  'dory-darker': $dory-darker,

  'peter-pan-lighter': $peter-pan-lighter,
  'peter-pan-light': $peter-pan-light,
  'peter-pan': $peter-pan,
  'peter-pan-dark': $peter-pan-dark,
  'peter-pan-darker': $peter-pan-darker,

  'simba-lighter': $simba-lighter,
  'simba-light': $simba-light,
  'simba': $simba,
  'simba-dark': $simba-dark,
  'simba-darker': $simba-darker,
  'simba-darkest': $simba-darkest,

  'sebastian-lighter': $sebastian-lighter,
  'sebastian-light': $sebastian-light,
  'sebastian': $sebastian,
  'sebastian-dark': $sebastian-dark,
  'sebastian-darker': $sebastian-darker,

  'ursula-light': $ursula-light,
  'ursula-darker': $ursula-darker,

  // Basic colors
  'white': $white,
  'black': $black,

  // Secondary Button Colors
  'secondary-btn-color': $secondary-btn-color,
  'secondary-btn-color-dark': $secondary-btn-color-dark,
  'secondary-btn-color-darker': $secondary-btn-color-darker,
  'secondary-dark-btn-color': $secondary-dark-btn-color,
  'secondary-dark-btn-color-dark': $secondary-dark-btn-color-dark,
  'secondary-dark-btn-color-darker': $secondary-dark-btn-color-darker,

  // Split Dropdown Button Colors
  'blue-hover-background-color': $blue-hover-background-color,
  'white-hover-text-color': $white-hover-text-color,

  // Transparent Colors
  'dory-light-transparent': $dory-light-transparent,
  'edna-transparent': $edna-transparent,

  // Alert colors
  'success-alert-background-color': $success-alert-background-color,

  // Text color
  'dark-text-blue-color': $dark-text-blue-color,
  'light-text-color': $light-text-color,
);
