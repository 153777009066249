@use 'styles/utilities/_variables' as common;

.homepage-above-the-fold-document-search {
  display: flex;
  justify-content: center;
  margin: 0 auto 113px;
  max-width: 468px;
  padding: 0 15px;
  width: 100%;

  div {
    width: 100%;
  }

  @media(min-width: common.$bootstrap-md-min-width) {
    padding: 0;
  }
}
