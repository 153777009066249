// Bootstrap
$bootstrap-sm-min-width: 576px;
$bootstrap-md-min-width: 768px;
$bootstrap-lg-min-width: 992px;
$bootstrap-xl-min-width: 1200px;

// @deprecated Please use the above bootstrap breakpoints whenever possible
$bootstrap-xxs-width: 480px;
$bootstrap-3-xs-width: 767px;
$desktop-medium-browser-width: 1100px;
$desktop-narrow-browser-width: 768px;
$desktop-wide-browser-width: 1300px;
$desktop-xl-browser-width: 1599px;
$popular-columns: 2;
$popular-column-gap: 20px;

// Media Query Mixins

// Small devices
@mixin sm-and-up {
  @media (min-width: #{$bootstrap-sm-min-width}) {
    @content;
  }
}

// Medium devices
@mixin md-and-up {
  @media (min-width: #{$bootstrap-md-min-width}) {
    @content;
  }
}

// Large devices
@mixin lg-and-up {
  @media (min-width: #{$bootstrap-lg-min-width}) {
    @content;
  }
}

// Extra large devices
@mixin xl-and-up {
  @media (min-width: #{$bootstrap-xl-min-width}) {
    @content;
  }
}

// Colors
$white: #fff;
$black: #000;
$gray: #ccc;
$gray-text-color: #333;
$light-gray-text-color: #999;
$light-grey-horizontal-rule-color: #a2a8a8;
$lighter-red-button-color: #ef6a6a;
$light-red-button-color: #d65555;
$white-grey-color: #c5cccc;

// Edit Pdf Page:
$light-grey-blue-about-pdf-bg-color: #ebf3f5;
$grey-about-header-color: #7c7c7c;
$grey-about-text-color: #6d6d6d;
$grey-edit-pdf-container-text-color: #3c3f3f;

// Footer
$social-media-container-color: #05355a;
$footer-dark-blue: #1b426d;
$footer-light-gray: #c2c7ca;

// Header
$header-color: #1b426d;
$header-grey: #b8b8b8;
$header-text-color-blue: #08c;

// Freemium Menu
$freemium-menu-drop-shadow-color: rgba(0, 0, 0, 0.3);
$freemium-menu-item-border-color: #e8e8e8;
$freemium-menu-item-text-color: #707986;
$freemium-menu-item-hover-background-color: #f2f2f2;

// Upgrade Page:
$upgrade-pricing-quote-color: #767676;

// Sidebar
$sidebar-min-desktop-width: 1176px;
$sidebar-background-grey: #dfe5eb;
$sidebar-sub-header-color: #999;
$sidebar-pricing-option-background-color: #183d67;
$sidebar-pricing-option-card-border-color: #e1e1e1;
$sidebar-pricing-option-body-color: #999;
$sidebar-pricing-option-text-color: #5fbae4;
$sidebar-upgrade-pricing-quote-color: #767676;
$sidebar-carousel-control-color: #1a3e66;
$sidebar-carousel-control-active-color: #b2bccc;
$sidebar-faq-question-border-color: #b2bccc;
$sidebar-faq-info-wrapper-background-color: #c6cedc;
$sidebar-checkout-border-color: #bfbfbf;
$sidebar-checkout-contact-info-border-color: #eee;

// UpgradePricing
$pricing-option-item-border-color: #9b9b9b;
// Checkout
$gray-placeholder-color: #d9d9d9;
$blue-active-input-color: #53c4ea;
$blue-active-input-shadow-color: rgba(83, 196, 234, 0.4);
$blue-active-label-color: #00a4db;
$red-error-input-color: #ee6a6a;
$red-error-label-color: #d65555;
$contact-info-color: #eee;
$checkout-label-color: #333;
$checkout-cvc-popover-background-color: #f5f5f5;
$checkout-countdown-timer-color: #f00;
$checkout-container-width: 93%;

// Cart Item
$cart-item-border-color: #c7c7c7;
$cart-item-text-color: #555;

// Bootstrap
$bootstrap-text-shadow: rgba(0, 0, 0, 0.25);

//Edit-Pdf
$edit-pdf-upload-wrapper-background-color: #e0e7e9;
$edit-pdf-upload-box-background-color: #ecf3f5;
$edit-pdf-upload-box-border-color: #636363;
$edit-pdf-upload-box-note-color: #767;
$edit-pdf-upload-box-active-border-color: #4fc7ed;
$edit-pdf-upload-divider-color: #b9c0c0;
$edit-pdf-upload-button-light-blue: #40b7d8;
$edit-pdf-upload-button-dark-blue: #52c8e9;
$edit-pdf-upload-button-light-green: #1c8829;
$edit-pdf-upload-button-dark-green: #1cbd46;
$edit-pdf-benefits-headline-color: #4fc7ed;

// Breadcrumbs
$breadcrumbs-text-color: #767676;
$breadcrumbs-light-text-color: #adadad;

//Confirmation
$transparent-black: rgba(0, 0, 0, 0.05);
$white-smoke-bg: #f5f5f5;

//Members Area
$fillable-fields-new-pill-color: #0071ae;

//Legal Templates
$legal-templates-header-link-bg-color: #0061fe;
