@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/_variables' as common;

.column {
  display: flex;
  flex-direction: column;

  &:first-child,
  :not(:last-child) {
    padding-bottom: 12px;
  }

  .form-link {
    margin: 0;
  }

  a {
    color: colors.$dory;
  }

  @media(min-width: common.$bootstrap-lg-min-width) {
    width: 50%;

    &:first-child {
      border-right: 1px solid colors.$dumbo;
      padding-bottom: 0;
      padding-right: 18px;
    }

    &:last-child {
      padding-left: 18px;
    }
  }
}
