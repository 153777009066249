@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/_variables' as common;

$homepage-above-the-fold-mobile-background-color-one: #dff7ff;
$homepage-above-the-fold-mobile-background-color-two: #d0ecf6;

.homepage-above-the-fold-section-wrapper {
  background-image: linear-gradient(180deg, $homepage-above-the-fold-mobile-background-color-one 70.47%, $homepage-above-the-fold-mobile-background-color-two 100%);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  margin-bottom: 125px;
  position: relative;
  text-align: center;

  @media (min-width: common.$bootstrap-lg-min-width) {
    background-image: url('/static/images/homepage/above-the-fold-bg.png');
    margin-bottom: 0;
  }
}

.homepage-above-the-fold-container {
  position: relative;
  z-index: 2;
}

.homepage-above-the-fold-banner {
  padding-top: 18px;
}

.homepage-above-the-fold-status {
  margin-bottom: 0;
}

.homepage-above-the-fold-header {
  color: colors.$edna;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin-top: 30px;
  padding: 0 36px;

  @media (min-width: common.$bootstrap-md-min-width) {
    font-size: 32px;
    line-height: 38px;
    margin-top: 84px;
  }
}

.homepage-above-the-fold-sub-header {
  color: colors.$edna;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  margin-bottom: 18px;
  padding: 6px 8px 0;

  @media (min-width: common.$bootstrap-md-min-width) {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 30px;
    padding: 0;
  }
}

.document-signature-img {
  bottom: -125px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 224px;

  @media (min-width: common.$bootstrap-lg-min-width) {
    bottom: auto;
    left: auto;
    margin: 0;
    right: 3%;
    top: 47px;
    width: 307px;
  }
}
