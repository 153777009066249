@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/_variables' as common;

/*
  Ideally the padding for the top and bottom is the same,
  but due to browser version compatibility we are adjusting the bottom padding to accomodate for the
  margins on the individual cards
  (See FreeResourcesCard.module.scss)
*/

.free-resources-container {
  align-items: center;
  background-color: colors.$dumbo-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 84px 24px 54px;
  text-align: center;
  width: 100%;

  @include common.lg-and-up {
    padding-bottom: 20px;
  }
}

.text-container {
  justify-content: center;
  margin-bottom: 41px;
  text-align: center;

  @include common.md-and-up {
    max-width: 631px;
  }
}

.section-description {
  font-size: 14px;
}

.free-resources-card-container {
  @include common.md-and-up {
    max-width: 830px;
  }

  @include common.xl-and-up {
    max-width: 970px;
  }
}

.free-resources-card {
  padding: 0 11px;
}
