@use 'styles/utilities/_variables' as common;
@use 'styles/DesignSystem/_colors' as colors;

$image-card-background: #d2eff8;
$card-border: #8cd4f2;

/*
  Ideally we would want to use 'gap' property to do the horizontal spacing between the components,
  but due to browser version compatibility we are currently using margin-bottom to emulate the gap property
*/

.card-element {
  background: #fff;
  border-left: solid 5px $card-border;
  display: flex;
  margin-bottom: 30px;
  min-height: 246px;
  padding: 16px 24px;
  text-align: left;
  width: 100%;

  @include common.lg-and-up {
    min-height: 230px;
  }

  @include common.xl-and-up {
    margin-bottom: 64px;
    min-height: 259px;
  }

  a {
    color: colors.$edna;
  }
}

.image-card-element {
  background: $image-card-background;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 30px;
  padding: 18px 24px;
  text-align: left;

  @include common.md-and-up {
    flex-direction: row;
    min-height: 230px;
  }
  @include common.xl-and-up {
    margin-bottom: 64px;
    min-height: 259px;
  }

  a {
    color: inherit;
  }
}

.shelf-image {
  height: auto;
  width: 100%;

  @include common.md-and-up {
    margin: 18px 24px;
    width: 50%;
  }

  @include common.xl-and-up {
    margin-right: 16px;
    width: 42%;
  }
}

.card-title {
  border-bottom: 1px solid colors.$edna;
  margin-bottom: 18px;
}

.card-description {
  font-size: 14px;
}
