@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/variables' as common;

.secondary-cta-info {
  color: colors.$edna-light;
  margin: 6px 0 18px;

  @media (min-width: common.$bootstrap-md-min-width) {
    max-width: 388px;
  }
}
