@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/variables' as common;

.secondary-cta-section {
  padding: 36px 0 72px;

  @media (min-width: common.$bootstrap-lg-min-width) {
    padding: 66px 0 54px;
  }
}

.secondary-cta-row {
  margin: 0;
}

.create-document-section,
.upload-file-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
  text-align: center;

  &:first-child {
    margin-bottom: 54px;
  }

  @media (min-width: common.$bootstrap-lg-min-width) {
    &:first-child {
      border-right: 1px solid colors.$dumbo-dark;
      margin-bottom: 0;
    }
  }

  @media (min-width: common.$bootstrap-xl-min-width) {
    padding: 0 48px;
  }
}
