@use 'styles/DesignSystem/_colors' as colors;
@use 'styles/utilities/_variables' as common;

.forms-container {
  max-width: 861px;
  padding-bottom: 48px;
  width: 100%;
}

.title {
  width: 100%;
}

.links-container {
  display: flex;
  flex-direction: column;

  @media(min-width: common.$bootstrap-lg-min-width) {
    flex-direction: row;
    width: 50%;
  }
}

.forms-description {
  @media(min-width: common.$bootstrap-lg-min-width) {
    padding-right: 72px;
    width: 50%;
  }
}

.content-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(min-width: common.$bootstrap-lg-min-width) {
    flex-direction: row;
  }
}
